(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/section-odds-live/assets/javascripts/ui-updates.js') >= 0) return;  svs.modules.push('/components/banner_layouts/section-odds-live/assets/javascripts/ui-updates.js');
"use strict";

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  productIds
} = svs.components.lbUtils.sportProducts;
const {
  formatOdds,
  getFormattedEyebrow,
  twoDigitTimeNumber,
  Queue
} = svs.banner_layouts.section_odds_live.helpers;
const logger = svs.core.log.getLogger('banner_layouts:section-odds-live');


class SectionUI {
  constructor(shouldReduceMotion) {
    _defineProperty(this, "cubes", []);
    _defineProperty(this, "offset", {
      x: 0,
      y: 0
    });
    _defineProperty(this, "shouldReduceMotion", false);
    _defineProperty(this, "animationComplete", false);
    _defineProperty(this, "requestAnimationFrameId", null);
    _defineProperty(this, "SPEED", 30);
    _defineProperty(this, "timerId", void 0);
    this.shouldReduceMotion = shouldReduceMotion;
    this.SPEED = shouldReduceMotion ? 0 : 30;
    this.update = this.update.bind(this);
  }

  handleElement(element) {
    const brandCube = element.querySelector('.brand-cube');
    if (brandCube) {
      brandCube.removeEventListener('animationend', this.onAnimationComplete);
      brandCube.addEventListener('animationend', this.onAnimationComplete);
      const cube = brandCube.querySelector('.brand-cube-faces');
      if (cube) {
        this.cubes.push(cube);
      }
    }
  }

  updateCube(cube) {
    var _cube$dataset;
    const productId = Number(cube === null || cube === void 0 || (_cube$dataset = cube.dataset) === null || _cube$dataset === void 0 ? void 0 : _cube$dataset.id);
    const delta = performance.now() * this.SPEED / 1000 % 360;
    this.offset.x = Math.cos(delta * Math.PI / 180) / 3;
    this.offset.y = Math.sin(delta * Math.PI / 180) / 3;
    let rx = -this.offset.x * 10 - 10;
    let ry = this.offset.y * 50 + 40;
    let rz = 8;
    switch (productId) {
      case productIds.FULLTRAFF:
      case productIds.MALTIPSET:
        rz = -8;
        break;
      case productIds.BINGO:
        rx = 7 - this.offset.x * 20;
        ry = 43 + this.offset.y * 50;
        rz = 351;
        break;
      default:
    }
    const sizeMatch = cube.offsetHeight;
    const fp = Math.min(1, cube.children[0].getBoundingClientRect().width / sizeMatch);
    const lp = Math.min(1, cube.children[3].getBoundingClientRect().width / sizeMatch);
    const tp = Math.min(1, cube.children[4].getBoundingClientRect().height / (sizeMatch * 0.5));
    const bp = Math.min(1, cube.children[5].getBoundingClientRect().height / (sizeMatch * 0.75));
    cube.style.transform = "rotateX(".concat(rx, "deg) rotateY(").concat(ry, "deg) rotateZ(").concat(rz, "deg)");
    cube.children[0].style.filter = "brightness(".concat(fp, ")");
    cube.children[3].style.filter = "brightness(".concat(lp, ")");
    cube.children[4].style.filter = "brightness(".concat(tp, ")");
    cube.children[5].style.filter = "brightness(".concat(bp + 0.75, ")");
    cube.dataset.delta = delta;
  }
  update() {
    if (this.cubes.length) {
      this.cubes.forEach(cube => this.updateCube(cube));
      if (!this.shouldReduceMotion || !this.animationComplete) {
        this.requestAnimationFrameId = requestAnimationFrame(this.update);
      }
    }
  }
  updateTimer(banner, time) {
    clearTimeout(this.timerId);
    this.matchClockUpdate(banner, time);
    this.timerId = setTimeout(() => this.updateTimer(banner, time + 1), 60000);
  }
  async matchClockUpdate(banner, time) {
    const timeWrapper = banner.querySelector('.hero-time');
    const timeElement = timeWrapper === null || timeWrapper === void 0 ? void 0 : timeWrapper.querySelector('span');
    const updateArialLabel = time => {
      if (timeWrapper) {
        timeWrapper.setAttribute('aria-label', time === 1 ? "".concat(time, " minut spelad") : "".concat(time, " minuter spelade"));
      }
    };
    if (timeElement) {
      if (Number(timeElement.textContent) !== time) {
        await new Promise(resolve => {
          timeWrapper.classList.add('hero-time-out');
          timeWrapper.addEventListener('transitionend', () => {
            timeWrapper.classList.add('hero-time-in');
            const timePlayed = twoDigitTimeNumber(time);
            timeElement.textContent = timePlayed;
            updateArialLabel(timePlayed);
            timeWrapper.classList.remove('hero-time-out');
            resolve();
          }, {
            once: true
          });
        });
        setTimeout(() => {
          timeWrapper.classList.remove('hero-time-in');
        }, 100);
      } else {
        const timePlayed = twoDigitTimeNumber(time);
        timeElement.textContent = timePlayed;
        updateArialLabel(timePlayed);
      }
    }
  }

  onAnimationComplete(e) {
    switch (e.animationName) {
      case 'cube-scale':
        this.animationComplete = true;
        break;
      default:
    }
  }

  bumpBetOfferOdds(element) {
    element.classList.add('bet-odds-changed');
    element.addEventListener('animationend', () => element.classList.remove('bet-odds-changed'), {
      once: true
    });
  }
  initialize() {
    this.requestAnimationFrameId = requestAnimationFrame(this.update);
  }
  reset() {
    cancelAnimationFrame(this.requestAnimationFrameId);
  }
  initialScore(eventId) {
    let home = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    let away = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    const banner = SectionUI.getBanner(eventId);
    if (!banner) {
      return;
    }
    const homeTeam = banner.querySelector('.js-hero-score-home');
    const awayTeam = banner.querySelector('.js-hero-score-away');
    [{
      el: homeTeam,
      score: home
    }, {
      el: awayTeam,
      score: away
    }].forEach(_ref => {
      let {
        el,
        score
      } = _ref;
      SectionUI.removeChildren(el);
      const span = document.createElement('span');
      span.textContent = score;
      el.appendChild(span);
    });
  }
  initialMatchClockUpdate(banner, matchClock) {
    const title = banner.querySelector('.hero-event-title');
    const timeWrapper = banner.querySelector('.hero-time') || document.createElement('div');
    timeWrapper.classList.add('hero-time');
    timeWrapper.classList.add('hero-time-initial');
    timeWrapper.classList.add('hero-time-initial-out');
    title.after(timeWrapper);
    const timeElement = document.createElement('span');
    const timePlayed = twoDigitTimeNumber(matchClock.minute);
    timeElement.textContent = timePlayed;
    if (matchClock.minute > 0) {
      timeElement.setAttribute('aria-label', matchClock.minute === 1 ? "".concat(timePlayed, " minut spelad") : "".concat(timePlayed, " minuter spelade"));
    }
    timeWrapper.appendChild(timeElement);
    const initialTransitionComplete = e => {
      if (e.target === timeElement && e.propertyName === 'transform') {
        timeWrapper.removeEventListener('transitionend', initialTransitionComplete);
        timeWrapper.classList.remove('hero-time-initial');
      }
    };
    timeWrapper.addEventListener('transitionend', initialTransitionComplete);
    setTimeout(() => timeWrapper.classList.remove('hero-time-initial-out'), 100);
  }
  transitionIntoLive(eventId) {
    const banner = SectionUI.getBanner(eventId);
    if (!banner) {
      return;
    }
    const queue = new Queue();
    const title = banner.querySelector('.hero-event-title');
    const delimiter = banner.querySelector('.js-hero-delimiter');
    title.style.transition = 'opacity .6s cubic-bezier(0.5, 1, 0.89, 1), max-height .3s cubic-bezier(0.45, 0, 0.55, 1)';
    queue.add(next => {
      title.style.opacity = 0;
      title.addEventListener('transitionend', next, {
        once: true
      });
    }).add(next => {
      const home = delimiter.querySelector('.js-hero-score-home');
      const away = delimiter.querySelector('.js-hero-score-away');
      [home, away].forEach(team => {
        SectionUI.removeChildren(team);
        const span = document.createElement('span');
        span.textContent = 0;
        team.appendChild(span);
      });
      title.style.maxHeight = "".concat(title.getBoundingClientRect().height, "px");
      title.classList.add('hero-event-title-live');
      delimiter.classList.add('hero-delimiter-score');
      next();
    }).add(next => {
      title.style.maxHeight = "".concat(title.scrollHeight + 20, "px");
      title.addEventListener('transitionend', next, {
        once: true
      });
    }).add(next => {
      title.style.opacity = 1;
      title.addEventListener('transitionend', next, {
        once: true
      });
    }).add(next => {
      title.removeAttribute('style');
      this.handleMatchClockUpdated({
        eventId,
        matchClock: {
          minute: 0,
          second: 0
        }
      }, true);
    }).run();
  }

  handleBetOfferOddsUpdated(payload) {
    let internal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const {
      eventId,
      outcomes
    } = payload;
    const banner = SectionUI.getBanner(eventId);
    if (!banner) {
      return;
    }
    logger.debug('BetOfferOddsUpdated', eventId, outcomes);
    outcomes.forEach(outcome => {
      var _betOfferBtn$dataset, _betOfferBtn$dataset2;
      const betOfferBtn = banner.querySelector(".btn-bet-offer[data-id=\"".concat(outcome.id, "\"]"));

      if (!betOfferBtn) {
        return;
      }
      const currentOdds = betOfferBtn !== null && betOfferBtn !== void 0 && (_betOfferBtn$dataset = betOfferBtn.dataset) !== null && _betOfferBtn$dataset !== void 0 && _betOfferBtn$dataset.odds ? Number((_betOfferBtn$dataset2 = betOfferBtn.dataset) === null || _betOfferBtn$dataset2 === void 0 ? void 0 : _betOfferBtn$dataset2.odds) : '';
      const btnOdds = betOfferBtn === null || betOfferBtn === void 0 ? void 0 : betOfferBtn.querySelector('.btn-bet-odds');
      const odds = outcome !== null && outcome !== void 0 && outcome.odds ? Number(outcome.odds) : '';
      if (btnOdds && currentOdds !== odds) {
        betOfferBtn.dataset.odds = odds || '';
        btnOdds.textContent = formatOdds(odds);
        this.bumpBetOfferOdds(btnOdds);
      }
    });
  }

  handlePreMatchUpdate(payload) {
    let internal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const {
      eventId,
      event,
      outcomes
    } = payload;
    const banner = SectionUI.getBanner(eventId);
    if (!banner) {
      return;
    }
    const {
      group,
      time
    } = getFormattedEyebrow(event);
    const eyebrowGroup = banner.querySelector('.js-hero-eyebrow-group');
    const eyebrowTime = banner.querySelector('.js-hero-eyebrow-time');
    if (eyebrowGroup && group !== eyebrowGroup.textContent) {
      eyebrowGroup.textContent = group;
    }
    if (eyebrowTime && time !== eyebrowTime.textContent) {
      eyebrowTime.textContent = time;
    }
    this.handleBetOfferOddsUpdated({
      eventId,
      outcomes
    }, true);
  }

  handleEventScoreUpdated(payload) {
    let internal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const {
      eventId,
      score
    } = payload;
    const banner = SectionUI.getBanner(eventId);
    if (!banner) {
      return;
    }
    if (!internal) {
      logger.debug('EventScoreUpdated', payload, 'internal', internal);
    }
    const delimiter = banner.querySelector('.js-hero-delimiter');
    if (delimiter) {
      var _home$dataset, _away$dataset;
      delimiter.classList.add('hero-delimiter-score');
      const home = delimiter.querySelector('.js-hero-score-home');
      const away = delimiter.querySelector('.js-hero-score-away');
      const lastHome = (home === null || home === void 0 || (_home$dataset = home.dataset) === null || _home$dataset === void 0 ? void 0 : _home$dataset.score) || 0;
      const lastAway = (away === null || away === void 0 || (_away$dataset = away.dataset) === null || _away$dataset === void 0 ? void 0 : _away$dataset.score) || 0;
      const updateScores = (element, values) => {
        SectionUI.removeChildren(element);
        values.forEach((value, index) => {
          const span = document.createElement('span');
          span.textContent = value;
          element.appendChild(span);
          if (index === 0) {
            element.style.width = "".concat(span.offsetWidth, "px");
          } else if (index === values.length - 1) {
            element.dataset.end = span.offsetWidth;
          }
        });
      };
      updateScores(home, [lastHome, score.home]);
      updateScores(away, [lastAway, score.away]);
      const transitionComplete = (element, newScore) => {
        setTimeout(() => {
          element.firstChild.remove();
          element.classList.remove('hero-update-score');
          element.dataset.score = newScore;
          element.removeAttribute('style');
          delete element.dataset.end;
        }, 300);
      };
      requestAnimationFrame(() => {
        if (score.home !== lastHome || score.away !== lastAway) {
          banner.getElemenById('live-updates').innerHTML = "M\xE5luppdatering: ".concat(score.home, ", ").concat(score.away);
        }
        if (score.home !== lastHome) {
          home.addEventListener('transitionend', e => transitionComplete(e.target, score.home), {
            once: true
          });
          home.classList.add('hero-update-score');
          home.style.width = "".concat(home.dataset.end, "px");
        }
        if (score.away !== lastAway) {
          away.addEventListener('transitionend', e => transitionComplete(e.target, score.away), {
            once: true
          });
          away.classList.add('hero-update-score');
          away.style.width = "".concat(away.dataset.end, "px");
        }
      });
    }
  }

  handleEventStateUpdated(payload) {
    let internal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const {
      id: eventId,
      state
    } = payload;
    const banner = SectionUI.getBanner(eventId);
    if (!banner) {
      return;
    }
    logger.debug('EventStateUpdated', eventId, state);
    const liveUpdates = banner.getElementById('live-updates');
    switch (state) {
      case 'STARTED':
        {
          var _home$dataset2, _away$dataset2;
          banner.classList.remove('is-finished');
          banner.classList.add('is-live');
          const home = banner.querySelector('.js-hero-score-home');
          const away = banner.querySelector('.js-hero-score-away');
          const homeScore = (home === null || home === void 0 || (_home$dataset2 = home.dataset) === null || _home$dataset2 === void 0 ? void 0 : _home$dataset2.score) || 0;
          const awayScore = (away === null || away === void 0 || (_away$dataset2 = away.dataset) === null || _away$dataset2 === void 0 ? void 0 : _away$dataset2.score) || 0;
          this.initialScore(eventId, homeScore, awayScore);
          this.transitionIntoLive(eventId);
          liveUpdates.innerHTML = 'Matchen har startat.';
          break;
        }
      case 'FINISHED':
        banner.classList.remove('is-live');
        banner.classList.add('is-finished');
        liveUpdates.innerHTML = 'Matchen är avslutad.';
        break;
      default:
    }
  }

  handleLiveEventOpened(payload) {
    let internal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const {
      liveEvent
    } = payload;
    const {
      event,
      liveData
    } = liveEvent;
    const {
      id: eventId,
      state
    } = event;
    const banner = SectionUI.getBanner(eventId);
    if (!banner) {
      return;
    }
    logger.debug('LiveEventOpened', eventId, state);
    switch (state) {
      case 'STARTED':
        {
          const score = liveData.score || {
            home: '0',
            away: '0',
            who: 'UNKNOWN'
          };
          banner.classList.remove('is-finished');
          banner.classList.add('is-live');
          this.initialScore(eventId, score.home, score.away);
          this.transitionIntoLive(eventId);
          break;
        }
      case 'FINISHED':
        banner.classList.remove('is-live');
        banner.classList.add('is-finished');
        break;
      default:
    }
  }

  async handleMatchClockUpdated(payload) {
    let internal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const {
      eventId,
      matchClock
    } = payload;
    const banner = SectionUI.getBanner(eventId);
    if (!banner) {
      return;
    }
    if (!internal) {
      logger.debug('MatchClockUpdated', eventId, matchClock);
    }
    const timeWrapper = banner.querySelector('.hero-time');
    const timeElement = timeWrapper === null || timeWrapper === void 0 ? void 0 : timeWrapper.querySelector('span');

    if (!timeElement) {
      this.initialMatchClockUpdate(banner, matchClock);
    } else {
      this.matchClockUpdate(banner, matchClock.minute);
      if (matchClock.running) {
        this.timerId = setTimeout(() => this.updateTimer(banner, matchClock.minute + 1), (60 - matchClock.second) * 1000);
      } else {
        clearTimeout(this.timerId);
      }
    }
  }
  static removeChildren(element) {
    if (element.firstChild) {
      while (element.firstChild) {
        element.firstChild.remove();
      }
    }
    return element;
  }
  static getBanner(eventId) {
    return document.querySelector(".js-hero-v2[data-eventid=\"".concat(eventId, "\"]"));
  }
}
setGlobal('svs.banner_layouts.section_odds_live.ui', {
  SectionUI
});

 })(window);