(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/section-odds-live/assets/javascripts/helpers.js') >= 0) return;  svs.modules.push('/components/banner_layouts/section-odds-live/assets/javascripts/helpers.js');


'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const trinidad = svs.isServer ? require('trinidad-core').core : svs.core;
const getLogger = svs.isServer ? trinidad.logger : svs.core.log.getLogger;
const kambiOffering = svs.isServer ? trinidad.components.require('oddset', 'kambi-offering').offering : svs.components.oddset.kambiOffering;
const deepLink = svs.isServer ? trinidad.components.require('oddset', 'kambi-offering').deepLink : svs.components.oddset.kambiOffering;
const sportProducts = svs.isServer ? trinidad.components.require('lb-utils', 'sport-products').api : svs.components.lbUtils.sportProducts;
const {
  getFormattedDate
} = svs.isServer ? trinidad.components.require('lb-utils', 'helper-formatdate').api : svs.components.lbUtils.helpers.formatDate;
const {
  getOddsetBetslipQueryString
} = svs.isServer ? trinidad.components.require('oddset', 'common').utils : svs.components.oddset.common.utils;
const BET_OFFER_CACHE_KEY = 'bet_offer_cache';
const EventState = Object.freeze({
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  FINISHED: 'FINISHED',
  UNKNOWN: 'UNKNOWN'
});
const BetOfferType = Object.freeze({
  HANDICAP: 1,
  ONE_X_TWO: 2,
  RESULT: 3,
  OUTRIGHT: 4,
  OVER_UNDER: 6,
  ASIAN_HANDICAP: 7,
  HALFTIME_FULLTIME: 8,
  SIDE_BET: 9,
  ODD_EVEN: 10,
  THREE_WAY_HCP: 11,
  DOUBLE_CHANCE: 12,
  HEAD_TO_HEAD: 13,
  SCORER: 17,
  YES_NO: 18,
  MULTIPOSITION: 19,
  ASIAN_OVER_UNDER: 21,
  POSITION: 117,
  WINNING_MARGIN: 124,
  PLAYER_OCCURRENCE_NUMBER: 125,
  PLAYER_LAST_OCCURRENCE: 126,
  PLAYER_OCCURRENCE_LINE: 127,
  OCCURRENCE_METHOD: 128 
});
const BetOfferTag = Object.freeze({
  MAIN: 'MAIN'
});
const OutcomeType = Object.freeze({
  OT_ONE: 'OT_ONE',
  OT_CROSS: 'OT_CROSS',
  OT_TWO: 'OT_TWO'
});
class Queue {
  constructor() {
    _defineProperty(this, "callbacks", []);
    this.next = this.next.bind(this);
    this.add = this.add.bind(this);
    this.run = this.run.bind(this);
  }
  next() {
    const cb = this.callbacks.shift();
    if (cb) {
      requestAnimationFrame(() => cb(this.next));
    }
  }
  add(callback) {
    this.callbacks.push(callback);
    return this;
  }
  run() {
    this.next();
  }
}
const logger = getLogger('banner_layouts:section-odds-live');
let betOfferIntervalId;
const getCacheHandler = isAdmin => ({
  clear: () => {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(BET_OFFER_CACHE_KEY);
    }
  },
  set: data => {
    if (typeof localStorage !== 'undefined' && isAdmin) {
      localStorage.setItem(BET_OFFER_CACHE_KEY, LZString.compressToUTF16(JSON.stringify(data)));
    }
  },
  get: () => {
    if (typeof localStorage !== 'undefined' && isAdmin) {
      const lsData = localStorage.getItem(BET_OFFER_CACHE_KEY);
      try {
        return JSON.parse(LZString.decompressFromUTF16(lsData)) || {};
      } catch (err) {
        logger.debug('Could not parse cache string, removing current and returning empty');
        localStorage.removeItem(BET_OFFER_CACHE_KEY);
        return {};
      }
    }
    return {};
  }
});
const getFormattedEyebrow = event => {
  const startDate = new Date(event === null || event === void 0 ? void 0 : event.start);
  const time = startDate.toLocaleString('sv-se', {
    hour: '2-digit',
    minute: '2-digit'
  });
  const formattedDate = getFormattedDate(startDate, true, true, true);
  switch (event.state) {
    case EventState.STARTED:
      return {
        group: event.group,
        time: ''
      };
    default:
      return {
        group: event.group,
        time: "".concat(formattedDate, " kl ").concat(time)
      };
  }
};
const formatOdds = odds => odds ? Number(odds / 1000).toFixed(2) : '';

const outcomeToString = (outcomeType, capitalize) => {
  let string;
  switch (outcomeType) {
    case OutcomeType.OT_ONE:
      string = 'Ett';
      break;
    case OutcomeType.OT_CROSS:
      string = 'Kryss';
      break;
    case OutcomeType.OT_TWO:
      string = 'Två';
      break;
    default:
      return '';
  }
  return capitalize ? string : string.toLowerCase();
};
const oddsReadable = odds => {
  if (!odds) {
    return '';
  }
  const num = Number(odds / 1000);
  const precision = Number.isInteger(num) ? 1 : 2;
  return num.toFixed(precision).replace('.', ',');
};
const createBetAction = (outcome, url) => {
  const odds = formatOdds(outcome.odds);
  let ariaLabel = "Spela p\xE5 utfall ".concat(outcomeToString(outcome.type));
  if (odds) {
    ariaLabel += ". Odds: ".concat(oddsReadable(outcome.odds));
  }
  return {
    ariaLabel,
    id: outcome.id,
    odds: outcome.odds,
    linkTitle: outcome === null || outcome === void 0 ? void 0 : outcome.label,
    linkOdds: odds,
    linkDescription: 'Bet button',
    linkUrl: "".concat(url).concat(getOddsetBetslipQueryString(outcome.id))
  };
};
const resolveBetOffer = (eventId, betOfferTypes, isAdmin) => new Promise(resolve => {
  const eventIdIsInteger = Number.isInteger(parseInt(eventId, 10));
  const cacheHandler = getCacheHandler(isAdmin);
  const cache = cacheHandler.get();
  let {
    event,
    eyebrow,
    betActions,
    eventCta
  } = cache;
  clearTimeout(betOfferIntervalId);
  const getAndCompileBetOffer = async () => {
    try {
      const betOfferResult = await kambiOffering.getBetOffers(Number(eventId), betOfferTypes, {
        includeParticipants: true,
        onlyMain: true
      });
      if (betOfferResult && !betOfferResult.error) {
        var _betOfferResult$event, _betOffers$filter;
        const betEvent = (betOfferResult === null || betOfferResult === void 0 || (_betOfferResult$event = betOfferResult.events) === null || _betOfferResult$event === void 0 ? void 0 : _betOfferResult$event[0]) || {};
        const betOffers = (betOfferResult === null || betOfferResult === void 0 ? void 0 : betOfferResult.betOffers) || [];
        const selectedBetOffer = (_betOffers$filter = betOffers.filter(item => item.tags.includes(BetOfferTag.MAIN))) === null || _betOffers$filter === void 0 ? void 0 : _betOffers$filter[0];
        const eventUrl = deepLink.getUrlForBetOffer(selectedBetOffer || betOffers[0], betOfferResult);
        event = {
          nameDelimiter: betEvent.nameDelimiter,
          homeName: betEvent.homeName,
          awayName: betEvent.awayName,
          group: betEvent.group,
          state: betEvent.state
        };
        eyebrow = getFormattedEyebrow(betEvent);
        if (selectedBetOffer) {
          betActions = selectedBetOffer.outcomes.map(outcome => createBetAction(outcome, eventUrl));
          eventCta = undefined;
        } else {
          betActions = [];
          eventCta = {
            linkTitle: 'Gå till spelet',
            linkDescription: 'Go to event button',
            linkUrl: eventUrl
          };
        }
      } else {
        event = eyebrow = betActions = eventCta = undefined;
      }
    } catch (err) {
      logger.info('resolveBetOffer error:', eventId, betOfferTypes, err);
    }
    cacheHandler.set({
      event,
      eyebrow,
      betActions,
      eventCta,
      id: eventId,
      time: Date.now()
    });
    resolve({
      event,
      eyebrow,
      betActions,
      eventCta
    });
  };
  const resolveWithCache = () => {
    cacheHandler.set(_objectSpread(_objectSpread({}, cache), {}, {
      id: eventId,
      time: Date.now()
    }));
    resolve({
      event,
      eyebrow,
      betActions,
      eventCta
    });
  };
  if (eventIdIsInteger && betOfferTypes) {
    if (isAdmin) {
      if (eventId !== cache.id) {
        betOfferIntervalId = setTimeout(getAndCompileBetOffer, 200);
      } else {
        resolveWithCache();
      }
    } else {
      getAndCompileBetOffer();
    }
  } else {
    resolveWithCache();
  }
});
const resolveLiveData = (eventId, isAdmin) => new Promise(resolve => {
  const getAndCompileLiveData = async () => {
    try {
      const liveDataResult = await kambiOffering.getLiveData(Number(eventId));
      if (liveDataResult && !liveDataResult.error) {
        logger.debug('liveDataResult', liveDataResult);
        resolve(liveDataResult);
      }
    } catch (err) {
      logger.info('resolveBetOffer error:', eventId, err);
    }
  };
  if (!isAdmin) {
    getAndCompileLiveData();
  }
});
const updateBetOfferPreMatch = async (eventId, betOfferTypes) => {
  const betOfferResult = await kambiOffering.getBetOffers(Number(eventId), betOfferTypes, {
    includeParticipants: true,
    onlyMain: true
  });
  let outcomes = [];
  let event = {};
  let matchClock;
  let score;
  if (betOfferResult && !betOfferResult.error) {
    var _betOfferResult$event2, _betOffers$filter2, _event;
    event = (betOfferResult === null || betOfferResult === void 0 || (_betOfferResult$event2 = betOfferResult.events) === null || _betOfferResult$event2 === void 0 ? void 0 : _betOfferResult$event2[0]) || {};
    const betOffers = (betOfferResult === null || betOfferResult === void 0 ? void 0 : betOfferResult.betOffers) || [];
    const selectedBetOffer = (_betOffers$filter2 = betOffers.filter(item => item.tags.includes(BetOfferTag.MAIN))) === null || _betOffers$filter2 === void 0 ? void 0 : _betOffers$filter2[0];
    outcomes = (selectedBetOffer === null || selectedBetOffer === void 0 ? void 0 : selectedBetOffer.outcomes) || [];
    if (((_event = event) === null || _event === void 0 ? void 0 : _event.state) === EventState.STARTED) {
      const {
        liveData
      } = await resolveLiveData(eventId);
      score = liveData === null || liveData === void 0 ? void 0 : liveData.score;
      matchClock = liveData === null || liveData === void 0 ? void 0 : liveData.matchClock;
    }
  }
  return {
    outcomes,
    event,
    matchClock,
    score
  };
};
const resolveCube = cube => {
  const productId = cube !== null ? Number(cube) : '';
  if (!productId) {
    return {
      productId: '',
      productName: ''
    };
  }
  return {
    productId,
    productName: productId === 11111 ? 'generic' : sportProducts.getClassName(productId)
  };
};
const resolveSportFromClassName = className => {
  switch (className) {
    case 'game-sport':
      return 'Sport';
    case 'game-basketball':
      return 'Basket';
    case 'table-games':
      return 'Casino';
    case 'game-soccer':
      return 'Fotboll';
    case 'game-handball':
      return 'Handboll';
    case 'icon-horse':
      return 'Hästar';
    case 'game-floorball':
      return 'Innebandy';
    case 'game-hockey':
      return 'Ishockey';
    case 'game-tennis':
      return 'Tennis';
    case 'game-winter':
      return 'Vintersport';
    default:
      return '';
  }
};
const twoDigitTimeNumber = num => num < 10 ? "0".concat(num) : num;

const oscillateAnimation = el => {
  el.getAnimations().forEach(anim => anim.cancel());
  const inner = el.offsetWidth;
  const outer = el.parentElement.offsetWidth;
  if (inner > outer) {
    const percent = (inner - outer) / inner * 100;
    return el.animate({
      transform: "translateX(-".concat(percent, "%)")
    }, {
      easing: 'cubic-bezier(0.45, 0, 0.55, 1)',
      duration: Math.max(2000, Math.round(percent * 75)),
      direction: 'alternate',
      iterations: Infinity
    });
  }
};

const slowPoll = function (fn) {
  let interval = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10000;
  let timeout;
  const poll = () => {
    fn();
    timeout = setTimeout(poll, interval);
  };
  timeout = setTimeout(poll, interval);
  return () => clearTimeout(timeout);
};
if (svs.isServer) {
  module.exports = {
    BetOfferType,
    BET_OFFER_CACHE_KEY,
    EventState,
    formatOdds,
    getCacheHandler,
    getFormattedEyebrow,
    oscillateAnimation,
    resolveBetOffer,
    resolveCube,
    resolveSportFromClassName,
    resolveLiveData,
    slowPoll,
    twoDigitTimeNumber,
    updateBetOfferPreMatch,
    Queue
  };
} else {
  setGlobal('svs.banner_layouts.section_odds_live.helpers', {
    BetOfferType,
    BET_OFFER_CACHE_KEY,
    EventState,
    formatOdds,
    getCacheHandler,
    getFormattedEyebrow,
    oscillateAnimation,
    resolveBetOffer,
    resolveCube,
    resolveSportFromClassName,
    resolveLiveData,
    slowPoll,
    twoDigitTimeNumber,
    updateBetOfferPreMatch,
    Queue
  });
}

 })(window);