(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/section-odds-live/assets/javascripts/init-actions.js') >= 0) return;  svs.modules.push('/components/banner_layouts/section-odds-live/assets/javascripts/init-actions.js');
"use strict";


const {
  getCacheHandler
} = svs.banner_layouts.section_odds_live.helpers;


const initialize = function () {
  let {
    useSockets = true
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    BetOfferType,
    EventState,
    oscillateAnimation,
    slowPoll,
    updateBetOfferPreMatch
  } = svs.banner_layouts.section_odds_live.helpers;
  const {
    SectionUI
  } = svs.banner_layouts.section_odds_live.ui;
  const {
    setupSockets,
    SocketMessageType
  } = svs.banner_layouts.section_odds_live.sockets;
  const logger = svs.core.log.getLogger('banner_layouts:section-odds-live');
  const betOfferTypes = [BetOfferType.ONE_X_TWO, BetOfferType.HEAD_TO_HEAD, BetOfferType.YES_NO];
  const polls = {};
  const elements = document.querySelectorAll('.js-hero-v2[data-type="section-odds-live"]');
  const shouldReduceMotion = window.matchMedia("(prefers-reduced-motion: reduce)") === true || window.matchMedia("(prefers-reduced-motion: reduce)").matches === true;
  const sectionUI = new SectionUI(shouldReduceMotion);
  const updateTitleLayout = el => {
    const title = el.querySelector('.js-hero-event-title');
    const home = el.querySelector('.js-hero-event-title-home');
    const homeText = home.firstElementChild;
    const away = el.querySelector('.js-hero-event-title-away');
    const awayText = away.firstElementChild;
    const delimiter = el.querySelector('.js-hero-delimiter');
    const half = (title.offsetWidth - delimiter.offsetWidth) / 2;
    const totalWidth = homeText.offsetWidth + delimiter.offsetWidth + awayText.offsetWidth;
    const titleOverflow = totalWidth > title.offsetWidth;
    const homeOverflow = homeText.offsetWidth > home.offsetWidth;
    const homeTextOver = homeText.offsetWidth > half;
    const awayOverflow = awayText.offsetWidth > away.offsetWidth;
    const awayTextOver = awayText.offsetWidth > half;
    title.style.setProperty('--home', titleOverflow && (homeOverflow || homeTextOver) ? '1fr' : 'auto');
    title.style.setProperty('--away', titleOverflow && (awayOverflow || awayTextOver) ? '1fr' : 'auto');
  };
  const resizeObserver = new ResizeObserver(entries => {
    for (const entry of entries) {
      entry.target.style = "\n        --height: ".concat(Math.round(entry.contentRect.height / 2), "px;\n        --width: ").concat(Math.round(entry.contentRect.width / 2), "px;\n      ");
      updateTitleLayout(entry.target);
    }
  });
  const reset = () => {
    elements.forEach(el => resizeObserver.unobserve(el));
    sectionUI.reset();
  };
  const clearPolls = eventId => {
    const selectedPolls = polls[eventId] || [];
    while (selectedPolls.length) {
      const clear = selectedPolls.pop();
      clear();
    }
  };

  const socketeUpdateTimes = {};

  const onSocketUpdate = messages => {
    messages.forEach(message => {
      const {
        payload,
        timestamp,
        type
      } = message;
      if (socketeUpdateTimes[type] === timestamp) {
        return;
      }
      socketeUpdateTimes[type] = timestamp;
      switch (type) {
        case SocketMessageType.BetOfferOddsUpdated:
          sectionUI.handleBetOfferOddsUpdated(payload);
          break;
        case SocketMessageType.EventScoreUpdated:
          sectionUI.handleEventScoreUpdated(payload);
          break;
        case SocketMessageType.EventStateUpdated:
          sectionUI.handleEventStateUpdated(payload);
          break;
        case SocketMessageType.LiveEventOpened:
          sectionUI.handleLiveEventOpened(payload);
          break;
        case SocketMessageType.MatchClockUpdated:
          sectionUI.handleMatchClockUpdated(payload);
          break;
        default:
          logger.debug("Unhandled socket update (".concat(SocketMessageType.toString(type), ")"), message);
      }
    });
  };

  const onSocketError = error => {
    logger.debug('Socket error', error);
  };
  reset();
  logger.debug('Initializing section-odds-live');

  elements.forEach(el => {
    var _el$closest, _el$dataset;
    if ((_el$closest = el.closest('.widget')) !== null && _el$closest !== void 0 && (_el$closest = _el$closest.nextSibling) !== null && _el$closest !== void 0 && _el$closest.querySelector('.category-menu-widget')) {
      el.classList.add('hero-sm-bottom-padding');
    }
    resizeObserver.observe(el);
    sectionUI.handleElement(el);
    const eventId = (_el$dataset = el.dataset) === null || _el$dataset === void 0 ? void 0 : _el$dataset.eventid;

    if (eventId && useSockets) {
      polls[eventId] = polls[eventId] || [];
      try {
        const {
          subscribe,
          unsubscribe
        } = setupSockets(eventId, onSocketUpdate, onSocketError);
        const onPoll = async () => {
          try {
            const {
              outcomes,
              event,
              matchClock
            } = await updateBetOfferPreMatch(eventId, betOfferTypes);
            if (event.state !== EventState.NOT_STARTED) {
              clearPolls(eventId);
              if (matchClock) {
                sectionUI.handleMatchClockUpdated({
                  eventId,
                  matchClock
                });
              }
            } else {
              sectionUI.handlePreMatchUpdate({
                eventId,
                outcomes,
                event
              });
            }
          } catch (error) {
            logger.debug('Error while fetching kambi bet offers', error.message, error.type, error);
            clearPolls(eventId);
          }
        };
        subscribe();
        const clearPoll = slowPoll(onPoll);
        polls[eventId].push(clearPoll);
        document.addEventListener('visibilitychange', () => {
          if (document.hidden) {
            logger.debug('Document hidden, clearing polls');
            unsubscribe();
            clearPolls(eventId);
          } else {
            logger.debug('Document visible, starting polls');
            subscribe();
            polls[eventId].push(slowPoll(onPoll));
          }
        });
        window.addEventListener('beforeunload', () => {
          unsubscribe();
          clearPolls(eventId);
        });
      } catch (error) {
        logger.error(error.message);
      }
    }

    el.querySelectorAll('.btn-bet-offer').forEach(btn => btn.addEventListener('mouseenter', () => oscillateAnimation(btn.querySelector('.btn-bet-text'))));
    el.classList.remove('hero-loading');
    updateTitleLayout(el);
  });
  sectionUI.initialize();
  window.addEventListener('unload', reset);
};
const preview = document.getElementById('js-cms-preview-container');
if (preview) {
  getCacheHandler().clear();
  const observer = new MutationObserver(() => initialize({
    useSockets: false
  }));
  observer.observe(preview, {
    childList: true
  });
}
svs.banner_layouts.section_odds_live.init = initialize;

 })(window);