(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/section-odds-live/views/index.js') >= 0) return;  svs.modules.push('/components/banner_layouts/section-odds-live/views/index.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_layouts=_svs.banner_layouts||{};
_svs.banner_layouts.section_odds_live=_svs.banner_layouts.section_odds_live||{};
_svs.banner_layouts.section_odds_live.templates=_svs.banner_layouts.section_odds_live.templates||{};
svs.banner_layouts.section_odds_live.templates.index = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"eyebrowManualAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":18,"column":2},"end":{"line":27,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"event") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(35, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":28,"column":2},"end":{"line":69,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"body") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":2},"end":{"line":74,"column":9}}})) != null ? stack1 : "")
    + "  <div class=\"hero-actions\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isBetActions") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":27},"end":{"line":75,"column":99}}})) != null ? stack1 : "")
    + ">\n    <span aria-hidden=\"true\" class=\"sr-only\" id="
    + alias2(((helper = (helper = lookupProperty(helpers,"actionDescriptionId") || (depth0 != null ? lookupProperty(depth0,"actionDescriptionId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"actionDescriptionId","hash":{},"data":data,"loc":{"start":{"line":76,"column":48},"end":{"line":76,"column":71}}}) : helper)))
    + ">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"event") : depth0)) != null ? lookupProperty(stack1,"homeName") : stack1), depth0))
    + " mot "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"event") : depth0)) != null ? lookupProperty(stack1,"awayName") : stack1), depth0))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"callToActions") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(42, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":4},"end":{"line":81,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"eventCta") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":4},"end":{"line":91,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n  <div aria-live=\"polite\" class=\"sr-only\" id=\"live-updates\"></div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"hero-eyebrow js-hero-eyebrow\">\n      <p class=\"hero-eyebrow-text js-hero-eyebrow-text\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"eyebrowManualAdmin") || (depth0 != null ? lookupProperty(depth0,"eyebrowManualAdmin") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"eyebrowManualAdmin","hash":{},"data":data,"loc":{"start":{"line":20,"column":56},"end":{"line":20,"column":78}}}) : helper)))
    + "</p>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p class=\"hero-eyebrow js-hero-eyebrow\">\n      <span class=\"hero-eyebrow-group js-hero-eyebrow-group\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"eyebrow") : depth0)) != null ? lookupProperty(stack1,"group") : stack1), depth0))
    + "</span>\n      <span class=\"hero-eyebrow-time js-hero-eyebrow-time\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"eyebrow") : depth0)) != null ? lookupProperty(stack1,"time") : stack1), depth0))
    + "</span>\n    </p>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"useH1") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":29,"column":4},"end":{"line":39,"column":11}}})) != null ? stack1 : "")
    + "      <span class=\"hero-event-title-ellipsis hero-event-title-home js-hero-event-title-home\">\n        <span class=\"team-name\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"event") : depth0)) != null ? lookupProperty(stack1,"homeName") : stack1), depth0))
    + "</span>\n      </span>\n      <span class=\"hero-delimiter js-hero-delimiter "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"live") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":52},"end":{"line":43,"column":92}}})) != null ? stack1 : "")
    + "\">\n        <span class=\"hero-score js-hero-score-home\" data-score=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":44,"column":64},"end":{"line":44,"column":117}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":10},"end":{"line":45,"column":69}}})) != null ? stack1 : "")
    + "</span>\n        <span class=\"delimiter\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"event") : depth0)) != null ? lookupProperty(stack1,"nameDelimiter") : stack1), depth0))
    + "</span>\n        <span class=\"hero-score js-hero-score-away\" data-score=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":48,"column":64},"end":{"line":48,"column":117}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":10},"end":{"line":49,"column":69}}})) != null ? stack1 : "")
    + "</span>\n      </span>\n      <span class=\"hero-event-title-ellipsis hero-event-title-away js-hero-event-title-away\">\n        <span class=\"team-name\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"event") : depth0)) != null ? lookupProperty(stack1,"awayName") : stack1), depth0))
    + "</span>\n      </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"useH1") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":55,"column":4},"end":{"line":59,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"timeDoubleDigit") : stack1),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":4},"end":{"line":64,"column":11}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <h1\n      class=\"hero-title hero-event-title"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"live") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":40},"end":{"line":31,"column":81}}})) != null ? stack1 : "")
    + " js-hero-event-title\"\n      aria-label=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"event") : depth0)) != null ? lookupProperty(stack1,"homeName") : stack1), depth0))
    + " mot "
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"event") : depth0)) != null ? lookupProperty(stack1,"awayName") : stack1), depth0))
    + "."
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":60},"end":{"line":32,"column":138}}})) != null ? stack1 : "")
    + "\"\n    >\n";
},"8":function(container,depth0,helpers,partials,data) {
    return " hero-event-title-live";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " Ställning: "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1)) != null ? lookupProperty(stack1,"home") : stack1), depth0))
    + ", "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1)) != null ? lookupProperty(stack1,"away") : stack1), depth0))
    + ".";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <h2\n      class=\"hero-title hero-event-title"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"live") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":40},"end":{"line":36,"column":81}}})) != null ? stack1 : "")
    + " js-hero-event-title\"\n      aria-label=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"event") : depth0)) != null ? lookupProperty(stack1,"homeName") : stack1), depth0))
    + " mot "
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"event") : depth0)) != null ? lookupProperty(stack1,"awayName") : stack1), depth0))
    + "."
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":60},"end":{"line":37,"column":138}}})) != null ? stack1 : "")
    + "\"\n    >\n";
},"14":function(container,depth0,helpers,partials,data) {
    return " hero-delimiter-score";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1)) != null ? lookupProperty(stack1,"home") : stack1), depth0));
},"18":function(container,depth0,helpers,partials,data) {
    return "0";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1)) != null ? lookupProperty(stack1,"home") : stack1), depth0))
    + "</span>";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1)) != null ? lookupProperty(stack1,"away") : stack1), depth0));
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1)) != null ? lookupProperty(stack1,"away") : stack1), depth0))
    + "</span>";
},"26":function(container,depth0,helpers,partials,data) {
    return "    </h1>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "    </h2>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"hero-time\" role=\"timer\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"time") : stack1), depth0))
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"time") : stack1),"===","1",{"name":"compare","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":61,"column":67},"end":{"line":61,"column":148}}})) != null ? stack1 : "")
    + "\">\n        <span>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"timeDoubleDigit") : stack1), depth0))
    + "</span>\n      </div>\n";
},"31":function(container,depth0,helpers,partials,data) {
    return " minut spelad";
},"33":function(container,depth0,helpers,partials,data) {
    return " minuter spelade";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"noEvent") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":2},"end":{"line":69,"column":2}}})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"hero-title hero-event-title\">\n      <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"noEvent") || (depth0 != null ? lookupProperty(depth0,"noEvent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"noEvent","hash":{},"data":data,"loc":{"start":{"line":67,"column":12},"end":{"line":67,"column":23}}}) : helper)))
    + "</span>\n    </div>\n  ";
},"38":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"hero-body\">\n      <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"body","hash":{},"data":data,"loc":{"start":{"line":72,"column":9},"end":{"line":72,"column":17}}}) : helper)))
    + "</p>\n    </div>\n";
},"40":function(container,depth0,helpers,partials,data) {
    return " role=\"group\" role-description=\"Matchbetting\"";
},"42":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"callToActions") : depth0),{"name":"each","hash":{},"fn":container.program(43, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":6},"end":{"line":80,"column":15}}})) != null ? stack1 : "");
},"43":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression((lookupProperty(helpers,"dynamicActionTemplate")||(depth0 && lookupProperty(depth0,"dynamicActionTemplate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"actionType") : depth0),(depth0 != null ? lookupProperty(depth0,"actionConfig") : depth0),(depths[1] != null ? lookupProperty(depths[1],"trackingString") : depths[1]),{"name":"dynamicActionTemplate","hash":{},"data":data,"loc":{"start":{"line":79,"column":8},"end":{"line":79,"column":75}}}))
    + "\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"button",{"name":"ui","hash":{"title":((stack1 = (depth0 != null ? lookupProperty(depth0,"eventCta") : depth0)) != null ? lookupProperty(stack1,"linkTitle") : stack1),"target":"internal","href":((stack1 = (depth0 != null ? lookupProperty(depth0,"eventCta") : depth0)) != null ? lookupProperty(stack1,"linkUrl") : stack1),"data-click-tracking":(depth0 != null ? lookupProperty(depth0,"trackingString") : depth0)},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":6},"end":{"line":90,"column":13}}})) != null ? stack1 : "");
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"eventCta") : depth0)) != null ? lookupProperty(stack1,"linkTitle") : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-lb_ui-hero-hero"),depth0,{"name":"components-lb_ui-hero-hero","hash":{"roleDescription":(depth0 != null ? lookupProperty(depth0,"roleDescription") : depth0),"width":(depth0 != null ? lookupProperty(depth0,"width") : depth0),"trackingString":(depth0 != null ? lookupProperty(depth0,"trackingString") : depth0),"tab":(depth0 != null ? lookupProperty(depth0,"tab") : depth0),"slim":(depth0 != null ? lookupProperty(depth0,"slim") : depth0),"order":(depth0 != null ? lookupProperty(depth0,"order") : depth0),"loading":(depth0 != null ? lookupProperty(depth0,"loading") : depth0),"invertArrow":(depth0 != null ? lookupProperty(depth0,"invertArrow") : depth0),"image":(depth0 != null ? lookupProperty(depth0,"image") : depth0),"height":(depth0 != null ? lookupProperty(depth0,"height") : depth0),"dataset":(depth0 != null ? lookupProperty(depth0,"dataset") : depth0),"cube":(depth0 != null ? lookupProperty(depth0,"cube") : depth0),"crm":(depth0 != null ? lookupProperty(depth0,"crm") : depth0),"class":(depth0 != null ? lookupProperty(depth0,"classes") : depth0),"branding":(depth0 != null ? lookupProperty(depth0,"branding") : depth0)},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.partials['components-banner_layouts-section_odds_live-index'] = svs.banner_layouts.section_odds_live.templates.index;
})(svs, Handlebars);


 })(window);